import styled from "@emotion/styled";

export const stripBgVariants = {
    'pink': `
      background: hotpink;
      color: black;
    `,
    'pink-strips': `
      background: hotpink;
      background: repeating-linear-gradient(-45deg, hotpink, hotpink 20px, black 22px, black 23px);
    `,
    'black': `
      background: black;
    `,
    'black-strips': `
      background: black;
      background: repeating-linear-gradient(45deg, black, black 20px, hotpink 22px, hotpink 23px);
    `,
    'yellow': `
      background: yellow;
      background: repeating-linear-gradient(45deg, yellow, yellow 20px, black 22px, black 23px);
    `
  };
  
export   const Strip = styled.div`
    ${({ variant }) => stripBgVariants[variant]}
    color: white;
    padding: 2rem;
  
    @media only screen and (min-width: 992px) {
      padding: 4rem;
    }
  `;
  
  