import React    from "react"
import {  Link } from "gatsby";
import styled from "@emotion/styled";
import { Strip } from './Strip';


const HeaderLink = styled(Link)`
  font-family: 'Fredoka One', cursive;
  background: white;
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  transform: rotate(5deg);
  transition: transform 0.5s;

  &:hover,
  &:focus {
    background: yellow;
    transform: rotate(-5deg);
  }
`;


const StripTitle = styled(Strip)`
  padding: 1rem;
  @media only screen and (min-width: 992px) {
    padding: 2rem 4rem;
  }
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`;

const PageTitle = styled.h1`
  font-family: 'Fredoka One', cursive;
  margin: 0;
  a {
    color: hotpink;
    font-size: 3rem;
    text-decoration: none;
  }
`;

export default function SiteHeader () {
    return (
        <StripTitle variant="black">
        <PageTitle>
          <a href="/">BurgerBot.net</a>
        </PageTitle>
        <HeaderLink to="/filling">Fillings</HeaderLink>
      </StripTitle>
    )
}